export const VEHICLE_STATUS = {
  0: "Unknown",
  1: "Ride In-progress",
  2: "In Maintenance",
  3: "Marked Broken",
  4: "Idle",
  5: "Retired",
};

export const VEHICLE_SERVICE_STATUS = {
  0: "Operational",
  1: "In Maintenance",
  2: "Marked Broken",
  3: "Retired",
};

export const VEHICLE_FUNCTIONAL_STATUS = {
  1: "ON",
  2: "OFF",
};

export const NOTIFICATION_PRIORITY_STATUS = ["","High","Medium","Low"];

export const ISSUE_STATUS = {
  0: "Unknown",
  1: "Completed",
  2: "Pending",
  3: "Cancelled",
};

export const ISSUE_TYPE = {
  0: "Unknown",
  1: "Accident",
  2: "Service",
  3: "Vehicle Missing",
  4: "Breakdown",
  5: "Roadside Breakdown",
  6: "Account Related"
};
export const KIOSK_ISSUE_TYPE = {
  0: "Other",
  1: "Electrical failure",
  2: "Fire or smoke",
  3: "Temperature rise",
  4: "Battery swap",
  5: "Mechanical failure",
  6: "Noise",
};

export const USER_TYPE = {
  0: "No role assigned",
  1: "Super Admin",
  2: "Fleet Owner Admin",
  3: "Rider",
  4: "Mechanic",
  5: "Fleet Client Admin",
  6: "Operator",
};

export const CLIENTS = {
  0: "Foodpanda",
  1: "TCS",
  2: "Leopard Courier",
  3: "M&P Courier",
  4: "Careem",
  5: "Bikea",
  6: "Uber",
};
