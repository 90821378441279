//initialize battery heartbeat
import {BatteryHBItem, GetBatteryHBRequest, GetBatteryHBResponse} from "../../protobuf/battery_service_pb";
import {
    BatteryActions,
    BatteryHeartbeat,
    BatteryHeartbeatAction,
    BillingAction,
    BillingState,
    CustomerBillingItem
} from "../../types";
import {Dispatch} from "react";
import {apiResponse} from "./common-actions";
import {BatteryManagementServiceClient} from "../../protobuf/Battery_serviceServiceClientPb";
import {batteryManagmentServiceClient, billingServiceClient} from "../../utils/grpc-clients";
import {useDispatch} from "react-redux";
import {GetLatestBillByCustomerRequest, GetLatestBillByCustomerResponse} from "../../protobuf/billing_service_pb";
import {BillingServiceClient} from "../../protobuf/Billing_serviceServiceClientPb";
import {Struct} from "google-protobuf/google/protobuf/struct_pb";

export function initializeCustomerBilling({organization_id, date}:{organization_id:string, date:string}){
    console.log("customer id : ", organization_id)
    console.log("Date : ", date)
    const request = new GetLatestBillByCustomerRequest()
    request.setCustomerId(organization_id || "785c0555-1853-48fa-8b5a-09480971d74b")
    request.setDate(date|| '')
    
    return async (dispatch: Dispatch<any>) => {
        function dispatcher(resp: GetLatestBillByCustomerResponse) {
            const customerBill: CustomerBillingItem = resp?.getBill()?.toObject() as CustomerBillingItem
            dispatch(setCustomerBill(customerBill))
            const totalBill = getTotalFromStruct(resp?.getBill()?.getComponents(), 'grandTotal')
            console.log("total bill from action : ", totalBill)
            dispatch(setTotalBill("PKR "+totalBill))
        }
        dispatch(
            apiResponse<
                BillingServiceClient,
                GetLatestBillByCustomerRequest,
                GetLatestBillByCustomerResponse
            >({
                client: billingServiceClient,
                action: "getLatestBillByCustomer",
                request,
                dispatcher,
            })
        );
    };
}
//end battery heartbeat

export function setCustomerBill(
    payload: CustomerBillingItem
): BillingAction {
    console.log("Bill : ", payload)
    return {
        type: "SET_BILL",
        payload,
    }
}


export function setTotalBill(payload:string):BillingAction {
    return {
        type: "SET_TOTAL_BILL",
        payload
    }
}


export function getTotalFromStruct(struct?: Struct, key?: string): number | null {
    const fieldsMap = struct?.getFieldsMap();
    const totalField = fieldsMap?.get(key || "");

    if (totalField && totalField.hasNumberValue()) {
        return totalField.getNumberValue();
    }

    return null; // Return null if 'total' is not found or is not a number
}

 // Function to extract the numberValue for a given key
export function getNumberValue(fieldsMap: [string, { nullValue: number, numberValue: number, stringValue: string, boolValue: boolean }][], key: string): number | undefined {
    const entry = fieldsMap.find(([k]) => k === key);
    return entry ? entry[1].numberValue : undefined;
}

export function formatCamelCaseToString(camelCaseString:string) : string {
    // Insert a space before each uppercase letter and capitalize the first letter
    return camelCaseString
        .replace(/([A-Z])/g, ' $1')  // Insert space before each uppercase letter
        .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter

}

export function formatDate(inputDate:string) {
    console.log( 'DUE DATE:',inputDate);
    // Create a new Date object from the input date string
    const date = new Date(inputDate);
    // Define the array of month names
    const monthNames = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];

    // Format the date to "28 August 2024"
    const formattedDate = `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
    return formattedDate;
}

export const monthNames = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
];
