import { format } from "date-fns";

import { BatteryState } from "types/store";
import {BatteryHBItem} from "../../protobuf/battery_service_pb";

export function parseBatteryTable(
    search: BatteryState["search"],
    list?: BatteryState["batteries"],
    listType?: BatteryState["filters"]["listType"]
) {
    if (list) {
        const data = list.map((battery, index) => ({
            key: index,
            battery_id: battery.batteryId,
            battery_alias: battery.batteryAlias,
            soc_level: battery.socLevel || "-",
            battery_health: battery.batteryHealth,
            operational_state: battery.operationalState || "-",
            make_model: battery.make_model,
            battery_: battery.batteryAlias,
            host_id: battery.hostId != "00000000-0000-0000-0000-000000000000" ? battery.hostId : "-",
            action: "",
            raw: battery,
            listType: listType,
        }));
        return data
    }

    return [];
}

export function parseBatteryDetailTable(
    list?: BatteryState["batteryDetail"]
){
    console.log("list in battery detail parse", list);
    if (list) {
        const data = list.map((batteryDetail, index) => ({
            key: index,
            soc: batteryDetail.getBatterySoc(),
            operational_state: batteryDetail.getBatteryOperationalState(),
            host_id: batteryDetail.getBatteryHostIotId(),
            insertion_time: new Date(batteryDetail.getBatteryInsertionTime()).toUTCString(),
            battery_id: batteryDetail.getBatteryId(),
            host_vin: batteryDetail.getHostVin(),
            event_type: "Insert",
        }));
        return data
    }

    return [];
}

export function parseBatteryHeartbeatTable(
    list?: BatteryState["battery_heartbeat"]
){
    console.log("list in battery heartbeat parse", list);
    if (list) {
        const data = list.map((bh, index) => ({
            key: index,
            battery_id: bh.getBatteryId(),
            soc: bh.getSoc(),
            temperature: bh.getTemperature(),
            current_drawn: bh.getCurrentDrawn(),
            created_at: bh.getCreatedAt() || new Date().toLocaleString() ,
            charger_counter: bh.getChargerCounter(),
            voltage: bh.getVoltage(),
            current: bh.getCurrent(),
            cycles: bh.getCycles(),
            heartbeat: bh.getHeartbeat(),
            mosfet: bh.getMosfet(),
            charging: bh.getCharging(),
            mosfet_discharging: bh.getMosfetDischarging(),
            individual_cell_voltages: bh.getIndividualCellVoltages(),

        }));
        return data
    }

    return [];
}



