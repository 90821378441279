
import { NotificationsActions, NotificationsState } from "types/store";


const originalFilters = {
  "Priority":[
    { label: "High", value: "high" },
    { label: "Medium", value: "medium" },
    { label: "Low", value: "low" },
  ],

  // "Vehicles": [
  //   { label: "Zone Violation", value: "zone violation" },
  // ],
  //
  // "Kiosks": [
  //   { label: "Slot Malfunction", value: "234534c546c241" },
  //   { label: "Swap Denied", value: "413r23f3f2323" },
  // ],
  //
  // "Batteries": [
  //   { label: "Battery Malfunction", value: "234534c546c241" },
  // ],
  
  "Status": [
    { label: "Unread Only", value: "unread" },

  ],
 
};

const updatedFilters = {
  "Status": [],
  "Priority": [],
};

const initialState: NotificationsState = {
  page: {
    selectedPage: 1,
    totalElements: 0,
    totalPages: 0,
  },
  filters: {
    original: originalFilters,
    updated: updatedFilters,
  },
  search: "",
  showbulkActionsNotifications: false,

  logsPage:  {
    selectedPage: 1,
    totalElements: 0,
    totalPages: 0,
  },
};

export default function notificationsReducer(
  state: NotificationsState = initialState,
  action: NotificationsActions
): NotificationsState {
  switch (action.type) {
    case "SET_NOTIFICATIONS_TABLE":
      return { ...state, notificationsCenter: action.payload };
    case "SET_NOTIFICATION_SELECTED_PAGE":
      return {
        ...state,
        page: { ...state.page, selectedPage: action.payload },
      };
    case "SET_LOGS_SELECTED_PAGE":
      return {
        ...state,
        logsPage: { ...state.logsPage, selectedPage: action.payload },
      };
    case "SET_LOGS_TOTAL_PAGE":
        return { ...state, logsPage: { ...state.logsPage, ...action.payload } };
    case "SET_NOTIFICATION_TOTAL_PAGE":
      return { ...state, page: { ...state.page, ...action.payload } };
    case "SET_NOTIFICATIONS_ORIGINAL_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, original: action.payload },
      };
    case "SET_NOTIFICATIONS_UPDATED_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, updated: action.payload },
      };
      case "SET_NOTIFICATION_CATEGORY":
        return {
          ...state,
           categories:action.payload ,
        };
        case "SET_NOTIFICATION_SUB_CATEGORY":
          return {
            ...state,
             subCategories:action.payload ,
          };
    case "SET_NOTIFICATIONS_SEARCH":
      return {
        ...state,
        search: action.payload,
      };
      case "SET_NOTIFICATIONS_BULK_ACTIONS":
        return {
          ...state,
        showbulkActionsNotifications: action.payload,
        };
        case "SET_SELECTED_NOTIFICATIONS":
          return {
            ...state,
            selectedNotifications: action.payload,
          };
    default:
      return state;
  }
}
