/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.2
// source: vehicle_service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as vehicle_service_pb from './vehicle_service_pb';
import * as webhook_pb from './webhook_pb';
import * as kioskService_pb from './kioskService_pb';


export class VehicleServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetVehicle = new grpcWeb.MethodDescriptor(
    '/api.VehicleService/GetVehicle',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.GetVehicleRequest,
    vehicle_service_pb.GetVehicleResponse,
    (request: vehicle_service_pb.GetVehicleRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.GetVehicleResponse.deserializeBinary
  );

  getVehicle(
    request: vehicle_service_pb.GetVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.GetVehicleResponse>;

  getVehicle(
    request: vehicle_service_pb.GetVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.GetVehicleResponse>;

  getVehicle(
    request: vehicle_service_pb.GetVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleService/GetVehicle',
        request,
        metadata || {},
        this.methodDescriptorGetVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleService/GetVehicle',
    request,
    metadata || {},
    this.methodDescriptorGetVehicle);
  }

  methodDescriptorGetVehicleByDeviceIotID = new grpcWeb.MethodDescriptor(
    '/api.VehicleService/GetVehicleByDeviceIotID',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.ByIotIdRequest,
    vehicle_service_pb.GetVehicleResponse,
    (request: vehicle_service_pb.ByIotIdRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.GetVehicleResponse.deserializeBinary
  );

  getVehicleByDeviceIotID(
    request: vehicle_service_pb.ByIotIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.GetVehicleResponse>;

  getVehicleByDeviceIotID(
    request: vehicle_service_pb.ByIotIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.GetVehicleResponse>;

  getVehicleByDeviceIotID(
    request: vehicle_service_pb.ByIotIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleService/GetVehicleByDeviceIotID',
        request,
        metadata || {},
        this.methodDescriptorGetVehicleByDeviceIotID,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleService/GetVehicleByDeviceIotID',
    request,
    metadata || {},
    this.methodDescriptorGetVehicleByDeviceIotID);
  }

  methodDescriptorListVehicle = new grpcWeb.MethodDescriptor(
    '/api.VehicleService/ListVehicle',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.ListVehicleRequest,
    vehicle_service_pb.ListVehicleResponse,
    (request: vehicle_service_pb.ListVehicleRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.ListVehicleResponse.deserializeBinary
  );

  listVehicle(
    request: vehicle_service_pb.ListVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.ListVehicleResponse>;

  listVehicle(
    request: vehicle_service_pb.ListVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.ListVehicleResponse>;

  listVehicle(
    request: vehicle_service_pb.ListVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleService/ListVehicle',
        request,
        metadata || {},
        this.methodDescriptorListVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleService/ListVehicle',
    request,
    metadata || {},
    this.methodDescriptorListVehicle);
  }

  methodDescriptorGetAssignedVehicles = new grpcWeb.MethodDescriptor(
    '/api.VehicleService/GetAssignedVehicles',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.MyVehicleRequest,
    vehicle_service_pb.MyVehicleResponse,
    (request: vehicle_service_pb.MyVehicleRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.MyVehicleResponse.deserializeBinary
  );

  getAssignedVehicles(
    request: vehicle_service_pb.MyVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.MyVehicleResponse>;

  getAssignedVehicles(
    request: vehicle_service_pb.MyVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.MyVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.MyVehicleResponse>;

  getAssignedVehicles(
    request: vehicle_service_pb.MyVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.MyVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleService/GetAssignedVehicles',
        request,
        metadata || {},
        this.methodDescriptorGetAssignedVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleService/GetAssignedVehicles',
    request,
    metadata || {},
    this.methodDescriptorGetAssignedVehicles);
  }

  methodDescriptorGetKioskList = new grpcWeb.MethodDescriptor(
    '/api.VehicleService/GetKioskList',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.GetKioskListRequest,
    kioskService_pb.Kiosks,
    (request: vehicle_service_pb.GetKioskListRequest) => {
      return request.serializeBinary();
    },
    kioskService_pb.Kiosks.deserializeBinary
  );

  getKioskList(
    request: vehicle_service_pb.GetKioskListRequest,
    metadata: grpcWeb.Metadata | null): Promise<kioskService_pb.Kiosks>;

  getKioskList(
    request: vehicle_service_pb.GetKioskListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: kioskService_pb.Kiosks) => void): grpcWeb.ClientReadableStream<kioskService_pb.Kiosks>;

  getKioskList(
    request: vehicle_service_pb.GetKioskListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: kioskService_pb.Kiosks) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleService/GetKioskList',
        request,
        metadata || {},
        this.methodDescriptorGetKioskList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleService/GetKioskList',
    request,
    metadata || {},
    this.methodDescriptorGetKioskList);
  }

  methodDescriptorUpdateRegistrationPlateNumber = new grpcWeb.MethodDescriptor(
    '/api.VehicleService/UpdateRegistrationPlateNumber',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.UpdateRegistrationPlateNumberRequest,
    vehicle_service_pb.UpdateRegistrationPlateNumberResponse,
    (request: vehicle_service_pb.UpdateRegistrationPlateNumberRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.UpdateRegistrationPlateNumberResponse.deserializeBinary
  );

  updateRegistrationPlateNumber(
    request: vehicle_service_pb.UpdateRegistrationPlateNumberRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.UpdateRegistrationPlateNumberResponse>;

  updateRegistrationPlateNumber(
    request: vehicle_service_pb.UpdateRegistrationPlateNumberRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateRegistrationPlateNumberResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.UpdateRegistrationPlateNumberResponse>;

  updateRegistrationPlateNumber(
    request: vehicle_service_pb.UpdateRegistrationPlateNumberRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateRegistrationPlateNumberResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleService/UpdateRegistrationPlateNumber',
        request,
        metadata || {},
        this.methodDescriptorUpdateRegistrationPlateNumber,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleService/UpdateRegistrationPlateNumber',
    request,
    metadata || {},
    this.methodDescriptorUpdateRegistrationPlateNumber);
  }

  methodDescriptorUpdateVehicleRID = new grpcWeb.MethodDescriptor(
    '/api.VehicleService/UpdateVehicleRID',
    grpcWeb.MethodType.UNARY,
    webhook_pb.WebhookRequest,
    webhook_pb.WebhookResponse,
    (request: webhook_pb.WebhookRequest) => {
      return request.serializeBinary();
    },
    webhook_pb.WebhookResponse.deserializeBinary
  );

  updateVehicleRID(
    request: webhook_pb.WebhookRequest,
    metadata: grpcWeb.Metadata | null): Promise<webhook_pb.WebhookResponse>;

  updateVehicleRID(
    request: webhook_pb.WebhookRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: webhook_pb.WebhookResponse) => void): grpcWeb.ClientReadableStream<webhook_pb.WebhookResponse>;

  updateVehicleRID(
    request: webhook_pb.WebhookRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: webhook_pb.WebhookResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleService/UpdateVehicleRID',
        request,
        metadata || {},
        this.methodDescriptorUpdateVehicleRID,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleService/UpdateVehicleRID',
    request,
    metadata || {},
    this.methodDescriptorUpdateVehicleRID);
  }

}

export class VehicleManagementServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddVehicle = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/AddVehicle',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.AddVehicleRequest,
    vehicle_service_pb.AddVehicleResponse,
    (request: vehicle_service_pb.AddVehicleRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.AddVehicleResponse.deserializeBinary
  );

  addVehicle(
    request: vehicle_service_pb.AddVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.AddVehicleResponse>;

  addVehicle(
    request: vehicle_service_pb.AddVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AddVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.AddVehicleResponse>;

  addVehicle(
    request: vehicle_service_pb.AddVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AddVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/AddVehicle',
        request,
        metadata || {},
        this.methodDescriptorAddVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/AddVehicle',
    request,
    metadata || {},
    this.methodDescriptorAddVehicle);
  }

  methodDescriptorGetVehicle = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/GetVehicle',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.GetVehicleManagementRequest,
    vehicle_service_pb.GetVehicleManagementResponse,
    (request: vehicle_service_pb.GetVehicleManagementRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.GetVehicleManagementResponse.deserializeBinary
  );

  getVehicle(
    request: vehicle_service_pb.GetVehicleManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.GetVehicleManagementResponse>;

  getVehicle(
    request: vehicle_service_pb.GetVehicleManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetVehicleManagementResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.GetVehicleManagementResponse>;

  getVehicle(
    request: vehicle_service_pb.GetVehicleManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetVehicleManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/GetVehicle',
        request,
        metadata || {},
        this.methodDescriptorGetVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/GetVehicle',
    request,
    metadata || {},
    this.methodDescriptorGetVehicle);
  }

  methodDescriptorUpdateVehicle = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/UpdateVehicle',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.UpdateVehicleRequest,
    vehicle_service_pb.UpdateVehicleResponse,
    (request: vehicle_service_pb.UpdateVehicleRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.UpdateVehicleResponse.deserializeBinary
  );

  updateVehicle(
    request: vehicle_service_pb.UpdateVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.UpdateVehicleResponse>;

  updateVehicle(
    request: vehicle_service_pb.UpdateVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.UpdateVehicleResponse>;

  updateVehicle(
    request: vehicle_service_pb.UpdateVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/UpdateVehicle',
        request,
        metadata || {},
        this.methodDescriptorUpdateVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/UpdateVehicle',
    request,
    metadata || {},
    this.methodDescriptorUpdateVehicle);
  }

  methodDescriptorDeleteVehicle = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/DeleteVehicle',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.DeleteVehicleManagementRequest,
    vehicle_service_pb.DeleteVehicleManagementResponse,
    (request: vehicle_service_pb.DeleteVehicleManagementRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.DeleteVehicleManagementResponse.deserializeBinary
  );

  deleteVehicle(
    request: vehicle_service_pb.DeleteVehicleManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.DeleteVehicleManagementResponse>;

  deleteVehicle(
    request: vehicle_service_pb.DeleteVehicleManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.DeleteVehicleManagementResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.DeleteVehicleManagementResponse>;

  deleteVehicle(
    request: vehicle_service_pb.DeleteVehicleManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.DeleteVehicleManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/DeleteVehicle',
        request,
        metadata || {},
        this.methodDescriptorDeleteVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/DeleteVehicle',
    request,
    metadata || {},
    this.methodDescriptorDeleteVehicle);
  }

  methodDescriptorListClientVehicles = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/ListClientVehicles',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.ListVehiclesManagementRequest,
    vehicle_service_pb.ListVehiclesManagementResponse,
    (request: vehicle_service_pb.ListVehiclesManagementRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.ListVehiclesManagementResponse.deserializeBinary
  );

  listClientVehicles(
    request: vehicle_service_pb.ListVehiclesManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.ListVehiclesManagementResponse>;

  listClientVehicles(
    request: vehicle_service_pb.ListVehiclesManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListVehiclesManagementResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.ListVehiclesManagementResponse>;

  listClientVehicles(
    request: vehicle_service_pb.ListVehiclesManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListVehiclesManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/ListClientVehicles',
        request,
        metadata || {},
        this.methodDescriptorListClientVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/ListClientVehicles',
    request,
    metadata || {},
    this.methodDescriptorListClientVehicles);
  }

  methodDescriptorListAllClientVehicles = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/ListAllClientVehicles',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.ListDropDownVehiclesRequest,
    vehicle_service_pb.ListDropDownVehiclesResponse,
    (request: vehicle_service_pb.ListDropDownVehiclesRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.ListDropDownVehiclesResponse.deserializeBinary
  );

  listAllClientVehicles(
    request: vehicle_service_pb.ListDropDownVehiclesRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.ListDropDownVehiclesResponse>;

  listAllClientVehicles(
    request: vehicle_service_pb.ListDropDownVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListDropDownVehiclesResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.ListDropDownVehiclesResponse>;

  listAllClientVehicles(
    request: vehicle_service_pb.ListDropDownVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListDropDownVehiclesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/ListAllClientVehicles',
        request,
        metadata || {},
        this.methodDescriptorListAllClientVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/ListAllClientVehicles',
    request,
    metadata || {},
    this.methodDescriptorListAllClientVehicles);
  }

  methodDescriptorUploadCsvData = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/UploadCsvData',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.UploadCsvRequest,
    vehicle_service_pb.UploadCsvResponse,
    (request: vehicle_service_pb.UploadCsvRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.UploadCsvResponse.deserializeBinary
  );

  uploadCsvData(
    request: vehicle_service_pb.UploadCsvRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.UploadCsvResponse>;

  uploadCsvData(
    request: vehicle_service_pb.UploadCsvRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UploadCsvResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.UploadCsvResponse>;

  uploadCsvData(
    request: vehicle_service_pb.UploadCsvRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UploadCsvResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/UploadCsvData',
        request,
        metadata || {},
        this.methodDescriptorUploadCsvData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/UploadCsvData',
    request,
    metadata || {},
    this.methodDescriptorUploadCsvData);
  }

  methodDescriptorAssignVehicleToUser = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/AssignVehicleToUser',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.AssignVehicleRequest,
    vehicle_service_pb.AssignVehicleResponse,
    (request: vehicle_service_pb.AssignVehicleRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.AssignVehicleResponse.deserializeBinary
  );

  assignVehicleToUser(
    request: vehicle_service_pb.AssignVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.AssignVehicleResponse>;

  assignVehicleToUser(
    request: vehicle_service_pb.AssignVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AssignVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.AssignVehicleResponse>;

  assignVehicleToUser(
    request: vehicle_service_pb.AssignVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AssignVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/AssignVehicleToUser',
        request,
        metadata || {},
        this.methodDescriptorAssignVehicleToUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/AssignVehicleToUser',
    request,
    metadata || {},
    this.methodDescriptorAssignVehicleToUser);
  }

  methodDescriptorGetAssignedVehicleByUserID = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/GetAssignedVehicleByUserID',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.AssignedVehicleRequest,
    vehicle_service_pb.AssignedVehicleResponse,
    (request: vehicle_service_pb.AssignedVehicleRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.AssignedVehicleResponse.deserializeBinary
  );

  getAssignedVehicleByUserID(
    request: vehicle_service_pb.AssignedVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.AssignedVehicleResponse>;

  getAssignedVehicleByUserID(
    request: vehicle_service_pb.AssignedVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AssignedVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.AssignedVehicleResponse>;

  getAssignedVehicleByUserID(
    request: vehicle_service_pb.AssignedVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AssignedVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/GetAssignedVehicleByUserID',
        request,
        metadata || {},
        this.methodDescriptorGetAssignedVehicleByUserID,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/GetAssignedVehicleByUserID',
    request,
    metadata || {},
    this.methodDescriptorGetAssignedVehicleByUserID);
  }

  methodDescriptorGetAssignedAllVehicleByUserID = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/GetAssignedAllVehicleByUserID',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.AssignedVehicleRequest,
    vehicle_service_pb.AllAssignedVehicleResponse,
    (request: vehicle_service_pb.AssignedVehicleRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.AllAssignedVehicleResponse.deserializeBinary
  );

  getAssignedAllVehicleByUserID(
    request: vehicle_service_pb.AssignedVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.AllAssignedVehicleResponse>;

  getAssignedAllVehicleByUserID(
    request: vehicle_service_pb.AssignedVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AllAssignedVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.AllAssignedVehicleResponse>;

  getAssignedAllVehicleByUserID(
    request: vehicle_service_pb.AssignedVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AllAssignedVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/GetAssignedAllVehicleByUserID',
        request,
        metadata || {},
        this.methodDescriptorGetAssignedAllVehicleByUserID,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/GetAssignedAllVehicleByUserID',
    request,
    metadata || {},
    this.methodDescriptorGetAssignedAllVehicleByUserID);
  }

  methodDescriptorGetAvaibleAssignedAllVehicleByUserID = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/GetAvaibleAssignedAllVehicleByUserID',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.AssignedVehicleRequest,
    vehicle_service_pb.AllAvailableAssignedVehicleResponse,
    (request: vehicle_service_pb.AssignedVehicleRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.AllAvailableAssignedVehicleResponse.deserializeBinary
  );

  getAvaibleAssignedAllVehicleByUserID(
    request: vehicle_service_pb.AssignedVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.AllAvailableAssignedVehicleResponse>;

  getAvaibleAssignedAllVehicleByUserID(
    request: vehicle_service_pb.AssignedVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AllAvailableAssignedVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.AllAvailableAssignedVehicleResponse>;

  getAvaibleAssignedAllVehicleByUserID(
    request: vehicle_service_pb.AssignedVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AllAvailableAssignedVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/GetAvaibleAssignedAllVehicleByUserID',
        request,
        metadata || {},
        this.methodDescriptorGetAvaibleAssignedAllVehicleByUserID,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/GetAvaibleAssignedAllVehicleByUserID',
    request,
    metadata || {},
    this.methodDescriptorGetAvaibleAssignedAllVehicleByUserID);
  }

  methodDescriptorUpdateVehicleFirmwareVersion = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/UpdateVehicleFirmwareVersion',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.UpdateVehicleFirmwareVersionRequest,
    vehicle_service_pb.UpdateVehicleFirmwareVersionResponse,
    (request: vehicle_service_pb.UpdateVehicleFirmwareVersionRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.UpdateVehicleFirmwareVersionResponse.deserializeBinary
  );

  updateVehicleFirmwareVersion(
    request: vehicle_service_pb.UpdateVehicleFirmwareVersionRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.UpdateVehicleFirmwareVersionResponse>;

  updateVehicleFirmwareVersion(
    request: vehicle_service_pb.UpdateVehicleFirmwareVersionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateVehicleFirmwareVersionResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.UpdateVehicleFirmwareVersionResponse>;

  updateVehicleFirmwareVersion(
    request: vehicle_service_pb.UpdateVehicleFirmwareVersionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateVehicleFirmwareVersionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/UpdateVehicleFirmwareVersion',
        request,
        metadata || {},
        this.methodDescriptorUpdateVehicleFirmwareVersion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/UpdateVehicleFirmwareVersion',
    request,
    metadata || {},
    this.methodDescriptorUpdateVehicleFirmwareVersion);
  }

  methodDescriptorUploadVehicleFirmwareToSpaces = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/UploadVehicleFirmwareToSpaces',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.UploadVehicleFirmwareToSpacesRequest,
    vehicle_service_pb.UploadVehicleFirmwareToSpacesResponse,
    (request: vehicle_service_pb.UploadVehicleFirmwareToSpacesRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.UploadVehicleFirmwareToSpacesResponse.deserializeBinary
  );

  uploadVehicleFirmwareToSpaces(
    request: vehicle_service_pb.UploadVehicleFirmwareToSpacesRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.UploadVehicleFirmwareToSpacesResponse>;

  uploadVehicleFirmwareToSpaces(
    request: vehicle_service_pb.UploadVehicleFirmwareToSpacesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UploadVehicleFirmwareToSpacesResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.UploadVehicleFirmwareToSpacesResponse>;

  uploadVehicleFirmwareToSpaces(
    request: vehicle_service_pb.UploadVehicleFirmwareToSpacesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UploadVehicleFirmwareToSpacesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/UploadVehicleFirmwareToSpaces',
        request,
        metadata || {},
        this.methodDescriptorUploadVehicleFirmwareToSpaces,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/UploadVehicleFirmwareToSpaces',
    request,
    metadata || {},
    this.methodDescriptorUploadVehicleFirmwareToSpaces);
  }

  methodDescriptorUpdateBulkVehicleFirmwareVersion = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/UpdateBulkVehicleFirmwareVersion',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.UpdateBulkVehicleFirmwareVersionRequest,
    vehicle_service_pb.UpdateBulkVehicleFirmwareVersionResponse,
    (request: vehicle_service_pb.UpdateBulkVehicleFirmwareVersionRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.UpdateBulkVehicleFirmwareVersionResponse.deserializeBinary
  );

  updateBulkVehicleFirmwareVersion(
    request: vehicle_service_pb.UpdateBulkVehicleFirmwareVersionRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.UpdateBulkVehicleFirmwareVersionResponse>;

  updateBulkVehicleFirmwareVersion(
    request: vehicle_service_pb.UpdateBulkVehicleFirmwareVersionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateBulkVehicleFirmwareVersionResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.UpdateBulkVehicleFirmwareVersionResponse>;

  updateBulkVehicleFirmwareVersion(
    request: vehicle_service_pb.UpdateBulkVehicleFirmwareVersionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateBulkVehicleFirmwareVersionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/UpdateBulkVehicleFirmwareVersion',
        request,
        metadata || {},
        this.methodDescriptorUpdateBulkVehicleFirmwareVersion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/UpdateBulkVehicleFirmwareVersion',
    request,
    metadata || {},
    this.methodDescriptorUpdateBulkVehicleFirmwareVersion);
  }

  methodDescriptorUpdateMqttServerByVehicle = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/UpdateMqttServerByVehicle',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.UpdateMqttServerByVehicleRequest,
    vehicle_service_pb.UpdateMqttServerByVehicleResponse,
    (request: vehicle_service_pb.UpdateMqttServerByVehicleRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.UpdateMqttServerByVehicleResponse.deserializeBinary
  );

  updateMqttServerByVehicle(
    request: vehicle_service_pb.UpdateMqttServerByVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.UpdateMqttServerByVehicleResponse>;

  updateMqttServerByVehicle(
    request: vehicle_service_pb.UpdateMqttServerByVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateMqttServerByVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.UpdateMqttServerByVehicleResponse>;

  updateMqttServerByVehicle(
    request: vehicle_service_pb.UpdateMqttServerByVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateMqttServerByVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/UpdateMqttServerByVehicle',
        request,
        metadata || {},
        this.methodDescriptorUpdateMqttServerByVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/UpdateMqttServerByVehicle',
    request,
    metadata || {},
    this.methodDescriptorUpdateMqttServerByVehicle);
  }

  methodDescriptorGetBillingStats = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/GetBillingStats',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.GetBillingStatsRequest,
    vehicle_service_pb.GetBillingStatsResponse,
    (request: vehicle_service_pb.GetBillingStatsRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.GetBillingStatsResponse.deserializeBinary
  );

  getBillingStats(
    request: vehicle_service_pb.GetBillingStatsRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.GetBillingStatsResponse>;

  getBillingStats(
    request: vehicle_service_pb.GetBillingStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetBillingStatsResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.GetBillingStatsResponse>;

  getBillingStats(
    request: vehicle_service_pb.GetBillingStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetBillingStatsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/GetBillingStats',
        request,
        metadata || {},
        this.methodDescriptorGetBillingStats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/GetBillingStats',
    request,
    metadata || {},
    this.methodDescriptorGetBillingStats);
  }

  methodDescriptorGetTotalDistanceByClient = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/GetTotalDistanceByClient',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.GetTotalDistanceByClientRequest,
    vehicle_service_pb.GetTotalDistanceByClientResponse,
    (request: vehicle_service_pb.GetTotalDistanceByClientRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.GetTotalDistanceByClientResponse.deserializeBinary
  );

  getTotalDistanceByClient(
    request: vehicle_service_pb.GetTotalDistanceByClientRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.GetTotalDistanceByClientResponse>;

  getTotalDistanceByClient(
    request: vehicle_service_pb.GetTotalDistanceByClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetTotalDistanceByClientResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.GetTotalDistanceByClientResponse>;

  getTotalDistanceByClient(
    request: vehicle_service_pb.GetTotalDistanceByClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetTotalDistanceByClientResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/GetTotalDistanceByClient',
        request,
        metadata || {},
        this.methodDescriptorGetTotalDistanceByClient,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/GetTotalDistanceByClient',
    request,
    metadata || {},
    this.methodDescriptorGetTotalDistanceByClient);
  }

  methodDescriptorAddVehicleFromCSV = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/AddVehicleFromCSV',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.AddVehicleFromCSVRequest,
    vehicle_service_pb.AddVehicleFromCSVResponse,
    (request: vehicle_service_pb.AddVehicleFromCSVRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.AddVehicleFromCSVResponse.deserializeBinary
  );

  addVehicleFromCSV(
    request: vehicle_service_pb.AddVehicleFromCSVRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.AddVehicleFromCSVResponse>;

  addVehicleFromCSV(
    request: vehicle_service_pb.AddVehicleFromCSVRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AddVehicleFromCSVResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.AddVehicleFromCSVResponse>;

  addVehicleFromCSV(
    request: vehicle_service_pb.AddVehicleFromCSVRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AddVehicleFromCSVResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/AddVehicleFromCSV',
        request,
        metadata || {},
        this.methodDescriptorAddVehicleFromCSV,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/AddVehicleFromCSV',
    request,
    metadata || {},
    this.methodDescriptorAddVehicleFromCSV);
  }

  methodDescriptorGenerateVehicleRID = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/GenerateVehicleRID',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.GenerateVehicleRIDRequest,
    vehicle_service_pb.GenerateVehicleRIDResponse,
    (request: vehicle_service_pb.GenerateVehicleRIDRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.GenerateVehicleRIDResponse.deserializeBinary
  );

  generateVehicleRID(
    request: vehicle_service_pb.GenerateVehicleRIDRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.GenerateVehicleRIDResponse>;

  generateVehicleRID(
    request: vehicle_service_pb.GenerateVehicleRIDRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GenerateVehicleRIDResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.GenerateVehicleRIDResponse>;

  generateVehicleRID(
    request: vehicle_service_pb.GenerateVehicleRIDRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GenerateVehicleRIDResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/GenerateVehicleRID',
        request,
        metadata || {},
        this.methodDescriptorGenerateVehicleRID,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/GenerateVehicleRID',
    request,
    metadata || {},
    this.methodDescriptorGenerateVehicleRID);
  }

  methodDescriptorGetVehicleList = new grpcWeb.MethodDescriptor(
    '/api.VehicleManagementService/GetVehicleList',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.GetVehicleListRequest,
    vehicle_service_pb.GetVehicleListResponse,
    (request: vehicle_service_pb.GetVehicleListRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.GetVehicleListResponse.deserializeBinary
  );

  getVehicleList(
    request: vehicle_service_pb.GetVehicleListRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.GetVehicleListResponse>;

  getVehicleList(
    request: vehicle_service_pb.GetVehicleListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetVehicleListResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.GetVehicleListResponse>;

  getVehicleList(
    request: vehicle_service_pb.GetVehicleListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetVehicleListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleManagementService/GetVehicleList',
        request,
        metadata || {},
        this.methodDescriptorGetVehicleList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleManagementService/GetVehicleList',
    request,
    metadata || {},
    this.methodDescriptorGetVehicleList);
  }

}

export class VehicleAdminServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddVehicle = new grpcWeb.MethodDescriptor(
    '/api.VehicleAdminService/AddVehicle',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.AddVehicleRequest,
    vehicle_service_pb.AddVehicleResponse,
    (request: vehicle_service_pb.AddVehicleRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.AddVehicleResponse.deserializeBinary
  );

  addVehicle(
    request: vehicle_service_pb.AddVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.AddVehicleResponse>;

  addVehicle(
    request: vehicle_service_pb.AddVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AddVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.AddVehicleResponse>;

  addVehicle(
    request: vehicle_service_pb.AddVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AddVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleAdminService/AddVehicle',
        request,
        metadata || {},
        this.methodDescriptorAddVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleAdminService/AddVehicle',
    request,
    metadata || {},
    this.methodDescriptorAddVehicle);
  }

  methodDescriptorGetVehicle = new grpcWeb.MethodDescriptor(
    '/api.VehicleAdminService/GetVehicle',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.GetVehicleAdminRequest,
    vehicle_service_pb.GetVehicleAdminResponse,
    (request: vehicle_service_pb.GetVehicleAdminRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.GetVehicleAdminResponse.deserializeBinary
  );

  getVehicle(
    request: vehicle_service_pb.GetVehicleAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.GetVehicleAdminResponse>;

  getVehicle(
    request: vehicle_service_pb.GetVehicleAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetVehicleAdminResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.GetVehicleAdminResponse>;

  getVehicle(
    request: vehicle_service_pb.GetVehicleAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetVehicleAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleAdminService/GetVehicle',
        request,
        metadata || {},
        this.methodDescriptorGetVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleAdminService/GetVehicle',
    request,
    metadata || {},
    this.methodDescriptorGetVehicle);
  }

  methodDescriptorUpdateVehicle = new grpcWeb.MethodDescriptor(
    '/api.VehicleAdminService/UpdateVehicle',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.UpdateVehicleRequest,
    vehicle_service_pb.UpdateVehicleResponse,
    (request: vehicle_service_pb.UpdateVehicleRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.UpdateVehicleResponse.deserializeBinary
  );

  updateVehicle(
    request: vehicle_service_pb.UpdateVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.UpdateVehicleResponse>;

  updateVehicle(
    request: vehicle_service_pb.UpdateVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.UpdateVehicleResponse>;

  updateVehicle(
    request: vehicle_service_pb.UpdateVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleAdminService/UpdateVehicle',
        request,
        metadata || {},
        this.methodDescriptorUpdateVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleAdminService/UpdateVehicle',
    request,
    metadata || {},
    this.methodDescriptorUpdateVehicle);
  }

  methodDescriptorDeleteVehicle = new grpcWeb.MethodDescriptor(
    '/api.VehicleAdminService/DeleteVehicle',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.DeleteVehicleAdminRequest,
    vehicle_service_pb.DeleteVehicleAdminResponse,
    (request: vehicle_service_pb.DeleteVehicleAdminRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.DeleteVehicleAdminResponse.deserializeBinary
  );

  deleteVehicle(
    request: vehicle_service_pb.DeleteVehicleAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.DeleteVehicleAdminResponse>;

  deleteVehicle(
    request: vehicle_service_pb.DeleteVehicleAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.DeleteVehicleAdminResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.DeleteVehicleAdminResponse>;

  deleteVehicle(
    request: vehicle_service_pb.DeleteVehicleAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.DeleteVehicleAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleAdminService/DeleteVehicle',
        request,
        metadata || {},
        this.methodDescriptorDeleteVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleAdminService/DeleteVehicle',
    request,
    metadata || {},
    this.methodDescriptorDeleteVehicle);
  }

  methodDescriptorListClientVehicles = new grpcWeb.MethodDescriptor(
    '/api.VehicleAdminService/ListClientVehicles',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.ListVehiclesAdminRequest,
    vehicle_service_pb.ListVehiclesAdminResponse,
    (request: vehicle_service_pb.ListVehiclesAdminRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.ListVehiclesAdminResponse.deserializeBinary
  );

  listClientVehicles(
    request: vehicle_service_pb.ListVehiclesAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.ListVehiclesAdminResponse>;

  listClientVehicles(
    request: vehicle_service_pb.ListVehiclesAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListVehiclesAdminResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.ListVehiclesAdminResponse>;

  listClientVehicles(
    request: vehicle_service_pb.ListVehiclesAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListVehiclesAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleAdminService/ListClientVehicles',
        request,
        metadata || {},
        this.methodDescriptorListClientVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleAdminService/ListClientVehicles',
    request,
    metadata || {},
    this.methodDescriptorListClientVehicles);
  }

  methodDescriptorListAllClientVehicles = new grpcWeb.MethodDescriptor(
    '/api.VehicleAdminService/ListAllClientVehicles',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.ListDropDownVehiclesRequest,
    vehicle_service_pb.ListDropDownVehiclesResponse,
    (request: vehicle_service_pb.ListDropDownVehiclesRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.ListDropDownVehiclesResponse.deserializeBinary
  );

  listAllClientVehicles(
    request: vehicle_service_pb.ListDropDownVehiclesRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.ListDropDownVehiclesResponse>;

  listAllClientVehicles(
    request: vehicle_service_pb.ListDropDownVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListDropDownVehiclesResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.ListDropDownVehiclesResponse>;

  listAllClientVehicles(
    request: vehicle_service_pb.ListDropDownVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListDropDownVehiclesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleAdminService/ListAllClientVehicles',
        request,
        metadata || {},
        this.methodDescriptorListAllClientVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleAdminService/ListAllClientVehicles',
    request,
    metadata || {},
    this.methodDescriptorListAllClientVehicles);
  }

  methodDescriptorUploadCsvData = new grpcWeb.MethodDescriptor(
    '/api.VehicleAdminService/UploadCsvData',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.UploadCsvRequest,
    vehicle_service_pb.UploadCsvResponse,
    (request: vehicle_service_pb.UploadCsvRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.UploadCsvResponse.deserializeBinary
  );

  uploadCsvData(
    request: vehicle_service_pb.UploadCsvRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.UploadCsvResponse>;

  uploadCsvData(
    request: vehicle_service_pb.UploadCsvRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UploadCsvResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.UploadCsvResponse>;

  uploadCsvData(
    request: vehicle_service_pb.UploadCsvRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UploadCsvResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleAdminService/UploadCsvData',
        request,
        metadata || {},
        this.methodDescriptorUploadCsvData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleAdminService/UploadCsvData',
    request,
    metadata || {},
    this.methodDescriptorUploadCsvData);
  }

  methodDescriptorAssignVehicleToUser = new grpcWeb.MethodDescriptor(
    '/api.VehicleAdminService/AssignVehicleToUser',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.AssignVehicleRequest,
    vehicle_service_pb.AssignVehicleResponse,
    (request: vehicle_service_pb.AssignVehicleRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.AssignVehicleResponse.deserializeBinary
  );

  assignVehicleToUser(
    request: vehicle_service_pb.AssignVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.AssignVehicleResponse>;

  assignVehicleToUser(
    request: vehicle_service_pb.AssignVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AssignVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.AssignVehicleResponse>;

  assignVehicleToUser(
    request: vehicle_service_pb.AssignVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AssignVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleAdminService/AssignVehicleToUser',
        request,
        metadata || {},
        this.methodDescriptorAssignVehicleToUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleAdminService/AssignVehicleToUser',
    request,
    metadata || {},
    this.methodDescriptorAssignVehicleToUser);
  }

  methodDescriptorGetAssignedVehicleByUserID = new grpcWeb.MethodDescriptor(
    '/api.VehicleAdminService/GetAssignedVehicleByUserID',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.AssignedVehicleRequest,
    vehicle_service_pb.AssignedVehicleResponse,
    (request: vehicle_service_pb.AssignedVehicleRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.AssignedVehicleResponse.deserializeBinary
  );

  getAssignedVehicleByUserID(
    request: vehicle_service_pb.AssignedVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.AssignedVehicleResponse>;

  getAssignedVehicleByUserID(
    request: vehicle_service_pb.AssignedVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AssignedVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.AssignedVehicleResponse>;

  getAssignedVehicleByUserID(
    request: vehicle_service_pb.AssignedVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AssignedVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleAdminService/GetAssignedVehicleByUserID',
        request,
        metadata || {},
        this.methodDescriptorGetAssignedVehicleByUserID,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleAdminService/GetAssignedVehicleByUserID',
    request,
    metadata || {},
    this.methodDescriptorGetAssignedVehicleByUserID);
  }

}

export class VehicleModelServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetModel = new grpcWeb.MethodDescriptor(
    '/api.VehicleModelService/GetModel',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.GetModelRequest,
    vehicle_service_pb.GetModelResponse,
    (request: vehicle_service_pb.GetModelRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.GetModelResponse.deserializeBinary
  );

  getModel(
    request: vehicle_service_pb.GetModelRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.GetModelResponse>;

  getModel(
    request: vehicle_service_pb.GetModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetModelResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.GetModelResponse>;

  getModel(
    request: vehicle_service_pb.GetModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetModelResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleModelService/GetModel',
        request,
        metadata || {},
        this.methodDescriptorGetModel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleModelService/GetModel',
    request,
    metadata || {},
    this.methodDescriptorGetModel);
  }

}

export class VehicleModelManagementServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddModel = new grpcWeb.MethodDescriptor(
    '/api.VehicleModelManagementService/AddModel',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.AddModelManagmentRequest,
    vehicle_service_pb.AddModelManagmentResponse,
    (request: vehicle_service_pb.AddModelManagmentRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.AddModelManagmentResponse.deserializeBinary
  );

  addModel(
    request: vehicle_service_pb.AddModelManagmentRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.AddModelManagmentResponse>;

  addModel(
    request: vehicle_service_pb.AddModelManagmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AddModelManagmentResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.AddModelManagmentResponse>;

  addModel(
    request: vehicle_service_pb.AddModelManagmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AddModelManagmentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleModelManagementService/AddModel',
        request,
        metadata || {},
        this.methodDescriptorAddModel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleModelManagementService/AddModel',
    request,
    metadata || {},
    this.methodDescriptorAddModel);
  }

  methodDescriptorGetModel = new grpcWeb.MethodDescriptor(
    '/api.VehicleModelManagementService/GetModel',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.GetModelManagementRequest,
    vehicle_service_pb.GetModelManagementResponse,
    (request: vehicle_service_pb.GetModelManagementRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.GetModelManagementResponse.deserializeBinary
  );

  getModel(
    request: vehicle_service_pb.GetModelManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.GetModelManagementResponse>;

  getModel(
    request: vehicle_service_pb.GetModelManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetModelManagementResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.GetModelManagementResponse>;

  getModel(
    request: vehicle_service_pb.GetModelManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetModelManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleModelManagementService/GetModel',
        request,
        metadata || {},
        this.methodDescriptorGetModel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleModelManagementService/GetModel',
    request,
    metadata || {},
    this.methodDescriptorGetModel);
  }

  methodDescriptorListModels = new grpcWeb.MethodDescriptor(
    '/api.VehicleModelManagementService/ListModels',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.ListModelsManagementRequest,
    vehicle_service_pb.ListModelsManagementResponse,
    (request: vehicle_service_pb.ListModelsManagementRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.ListModelsManagementResponse.deserializeBinary
  );

  listModels(
    request: vehicle_service_pb.ListModelsManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.ListModelsManagementResponse>;

  listModels(
    request: vehicle_service_pb.ListModelsManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListModelsManagementResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.ListModelsManagementResponse>;

  listModels(
    request: vehicle_service_pb.ListModelsManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListModelsManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleModelManagementService/ListModels',
        request,
        metadata || {},
        this.methodDescriptorListModels,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleModelManagementService/ListModels',
    request,
    metadata || {},
    this.methodDescriptorListModels);
  }

  methodDescriptorListVehicleModels = new grpcWeb.MethodDescriptor(
    '/api.VehicleModelManagementService/ListVehicleModels',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.ListVehicleModelsRequest,
    vehicle_service_pb.ListVehicleModelsResponse,
    (request: vehicle_service_pb.ListVehicleModelsRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.ListVehicleModelsResponse.deserializeBinary
  );

  listVehicleModels(
    request: vehicle_service_pb.ListVehicleModelsRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.ListVehicleModelsResponse>;

  listVehicleModels(
    request: vehicle_service_pb.ListVehicleModelsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListVehicleModelsResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.ListVehicleModelsResponse>;

  listVehicleModels(
    request: vehicle_service_pb.ListVehicleModelsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListVehicleModelsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleModelManagementService/ListVehicleModels',
        request,
        metadata || {},
        this.methodDescriptorListVehicleModels,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleModelManagementService/ListVehicleModels',
    request,
    metadata || {},
    this.methodDescriptorListVehicleModels);
  }

}

export class VehicleModelAdminServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddModel = new grpcWeb.MethodDescriptor(
    '/api.VehicleModelAdminService/AddModel',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.AddModelAdminRequest,
    vehicle_service_pb.AddModelAdminResponse,
    (request: vehicle_service_pb.AddModelAdminRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.AddModelAdminResponse.deserializeBinary
  );

  addModel(
    request: vehicle_service_pb.AddModelAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.AddModelAdminResponse>;

  addModel(
    request: vehicle_service_pb.AddModelAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AddModelAdminResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.AddModelAdminResponse>;

  addModel(
    request: vehicle_service_pb.AddModelAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.AddModelAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleModelAdminService/AddModel',
        request,
        metadata || {},
        this.methodDescriptorAddModel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleModelAdminService/AddModel',
    request,
    metadata || {},
    this.methodDescriptorAddModel);
  }

  methodDescriptorGetModel = new grpcWeb.MethodDescriptor(
    '/api.VehicleModelAdminService/GetModel',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.GetModelAdminRequest,
    vehicle_service_pb.GetModelAdminResponse,
    (request: vehicle_service_pb.GetModelAdminRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.GetModelAdminResponse.deserializeBinary
  );

  getModel(
    request: vehicle_service_pb.GetModelAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.GetModelAdminResponse>;

  getModel(
    request: vehicle_service_pb.GetModelAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetModelAdminResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.GetModelAdminResponse>;

  getModel(
    request: vehicle_service_pb.GetModelAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetModelAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleModelAdminService/GetModel',
        request,
        metadata || {},
        this.methodDescriptorGetModel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleModelAdminService/GetModel',
    request,
    metadata || {},
    this.methodDescriptorGetModel);
  }

  methodDescriptorListModels = new grpcWeb.MethodDescriptor(
    '/api.VehicleModelAdminService/ListModels',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.ListModelsAdminRequest,
    vehicle_service_pb.ListModelsAdminResponse,
    (request: vehicle_service_pb.ListModelsAdminRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.ListModelsAdminResponse.deserializeBinary
  );

  listModels(
    request: vehicle_service_pb.ListModelsAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.ListModelsAdminResponse>;

  listModels(
    request: vehicle_service_pb.ListModelsAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListModelsAdminResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.ListModelsAdminResponse>;

  listModels(
    request: vehicle_service_pb.ListModelsAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListModelsAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleModelAdminService/ListModels',
        request,
        metadata || {},
        this.methodDescriptorListModels,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleModelAdminService/ListModels',
    request,
    metadata || {},
    this.methodDescriptorListModels);
  }

  methodDescriptorUpdateModel = new grpcWeb.MethodDescriptor(
    '/api.VehicleModelAdminService/UpdateModel',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.UpdateModelRequest,
    vehicle_service_pb.UpdateModelResponse,
    (request: vehicle_service_pb.UpdateModelRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.UpdateModelResponse.deserializeBinary
  );

  updateModel(
    request: vehicle_service_pb.UpdateModelRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.UpdateModelResponse>;

  updateModel(
    request: vehicle_service_pb.UpdateModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateModelResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.UpdateModelResponse>;

  updateModel(
    request: vehicle_service_pb.UpdateModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateModelResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleModelAdminService/UpdateModel',
        request,
        metadata || {},
        this.methodDescriptorUpdateModel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleModelAdminService/UpdateModel',
    request,
    metadata || {},
    this.methodDescriptorUpdateModel);
  }

  methodDescriptorDeleteModel = new grpcWeb.MethodDescriptor(
    '/api.VehicleModelAdminService/DeleteModel',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.DeleteModelRequest,
    vehicle_service_pb.DeleteModelResponse,
    (request: vehicle_service_pb.DeleteModelRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.DeleteModelResponse.deserializeBinary
  );

  deleteModel(
    request: vehicle_service_pb.DeleteModelRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.DeleteModelResponse>;

  deleteModel(
    request: vehicle_service_pb.DeleteModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.DeleteModelResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.DeleteModelResponse>;

  deleteModel(
    request: vehicle_service_pb.DeleteModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.DeleteModelResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleModelAdminService/DeleteModel',
        request,
        metadata || {},
        this.methodDescriptorDeleteModel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleModelAdminService/DeleteModel',
    request,
    metadata || {},
    this.methodDescriptorDeleteModel);
  }

  methodDescriptorUploadFirmwareToModel = new grpcWeb.MethodDescriptor(
    '/api.VehicleModelAdminService/UploadFirmwareToModel',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.UploadFirmwareToModelRequest,
    vehicle_service_pb.UploadFirmwareToModelResponse,
    (request: vehicle_service_pb.UploadFirmwareToModelRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.UploadFirmwareToModelResponse.deserializeBinary
  );

  uploadFirmwareToModel(
    request: vehicle_service_pb.UploadFirmwareToModelRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.UploadFirmwareToModelResponse>;

  uploadFirmwareToModel(
    request: vehicle_service_pb.UploadFirmwareToModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UploadFirmwareToModelResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.UploadFirmwareToModelResponse>;

  uploadFirmwareToModel(
    request: vehicle_service_pb.UploadFirmwareToModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UploadFirmwareToModelResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleModelAdminService/UploadFirmwareToModel',
        request,
        metadata || {},
        this.methodDescriptorUploadFirmwareToModel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleModelAdminService/UploadFirmwareToModel',
    request,
    metadata || {},
    this.methodDescriptorUploadFirmwareToModel);
  }

  methodDescriptorUpdateFirmwareVersionToModel = new grpcWeb.MethodDescriptor(
    '/api.VehicleModelAdminService/UpdateFirmwareVersionToModel',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.UpdateFirmwareVersionToModelRequest,
    vehicle_service_pb.UpdateFirmwareVersionToModelResponse,
    (request: vehicle_service_pb.UpdateFirmwareVersionToModelRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.UpdateFirmwareVersionToModelResponse.deserializeBinary
  );

  updateFirmwareVersionToModel(
    request: vehicle_service_pb.UpdateFirmwareVersionToModelRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.UpdateFirmwareVersionToModelResponse>;

  updateFirmwareVersionToModel(
    request: vehicle_service_pb.UpdateFirmwareVersionToModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateFirmwareVersionToModelResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.UpdateFirmwareVersionToModelResponse>;

  updateFirmwareVersionToModel(
    request: vehicle_service_pb.UpdateFirmwareVersionToModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateFirmwareVersionToModelResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleModelAdminService/UpdateFirmwareVersionToModel',
        request,
        metadata || {},
        this.methodDescriptorUpdateFirmwareVersionToModel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleModelAdminService/UpdateFirmwareVersionToModel',
    request,
    metadata || {},
    this.methodDescriptorUpdateFirmwareVersionToModel);
  }

  methodDescriptorUpdateFirmwareOnVehicles = new grpcWeb.MethodDescriptor(
    '/api.VehicleModelAdminService/UpdateFirmwareOnVehicles',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.UpdateFirmwareOnVehiclesRequest,
    vehicle_service_pb.UpdateFirmwareOnVehiclesResponse,
    (request: vehicle_service_pb.UpdateFirmwareOnVehiclesRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.UpdateFirmwareOnVehiclesResponse.deserializeBinary
  );

  updateFirmwareOnVehicles(
    request: vehicle_service_pb.UpdateFirmwareOnVehiclesRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.UpdateFirmwareOnVehiclesResponse>;

  updateFirmwareOnVehicles(
    request: vehicle_service_pb.UpdateFirmwareOnVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateFirmwareOnVehiclesResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.UpdateFirmwareOnVehiclesResponse>;

  updateFirmwareOnVehicles(
    request: vehicle_service_pb.UpdateFirmwareOnVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.UpdateFirmwareOnVehiclesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleModelAdminService/UpdateFirmwareOnVehicles',
        request,
        metadata || {},
        this.methodDescriptorUpdateFirmwareOnVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleModelAdminService/UpdateFirmwareOnVehicles',
    request,
    metadata || {},
    this.methodDescriptorUpdateFirmwareOnVehicles);
  }

  methodDescriptorListFirmwares = new grpcWeb.MethodDescriptor(
    '/api.VehicleModelAdminService/ListFirmwares',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.ListFirmwaresRequest,
    vehicle_service_pb.ListFirmwaresResponse,
    (request: vehicle_service_pb.ListFirmwaresRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.ListFirmwaresResponse.deserializeBinary
  );

  listFirmwares(
    request: vehicle_service_pb.ListFirmwaresRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.ListFirmwaresResponse>;

  listFirmwares(
    request: vehicle_service_pb.ListFirmwaresRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListFirmwaresResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.ListFirmwaresResponse>;

  listFirmwares(
    request: vehicle_service_pb.ListFirmwaresRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.ListFirmwaresResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleModelAdminService/ListFirmwares',
        request,
        metadata || {},
        this.methodDescriptorListFirmwares,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleModelAdminService/ListFirmwares',
    request,
    metadata || {},
    this.methodDescriptorListFirmwares);
  }

}

export class VehicleCheckInReturnServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetVehicleByIotID = new grpcWeb.MethodDescriptor(
    '/api.VehicleCheckInReturnService/GetVehicleByIotID',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.ByIotIdRequest,
    vehicle_service_pb.GetVehicleResponse,
    (request: vehicle_service_pb.ByIotIdRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.GetVehicleResponse.deserializeBinary
  );

  getVehicleByIotID(
    request: vehicle_service_pb.ByIotIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.GetVehicleResponse>;

  getVehicleByIotID(
    request: vehicle_service_pb.ByIotIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetVehicleResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.GetVehicleResponse>;

  getVehicleByIotID(
    request: vehicle_service_pb.ByIotIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.GetVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleCheckInReturnService/GetVehicleByIotID',
        request,
        metadata || {},
        this.methodDescriptorGetVehicleByIotID,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleCheckInReturnService/GetVehicleByIotID',
    request,
    metadata || {},
    this.methodDescriptorGetVehicleByIotID);
  }

  methodDescriptorCheckoutVehicle = new grpcWeb.MethodDescriptor(
    '/api.VehicleCheckInReturnService/CheckoutVehicle',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.VehicleCheckoutRequest,
    vehicle_service_pb.VehicleCheckoutResponse,
    (request: vehicle_service_pb.VehicleCheckoutRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.VehicleCheckoutResponse.deserializeBinary
  );

  checkoutVehicle(
    request: vehicle_service_pb.VehicleCheckoutRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.VehicleCheckoutResponse>;

  checkoutVehicle(
    request: vehicle_service_pb.VehicleCheckoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.VehicleCheckoutResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.VehicleCheckoutResponse>;

  checkoutVehicle(
    request: vehicle_service_pb.VehicleCheckoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.VehicleCheckoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleCheckInReturnService/CheckoutVehicle',
        request,
        metadata || {},
        this.methodDescriptorCheckoutVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleCheckInReturnService/CheckoutVehicle',
    request,
    metadata || {},
    this.methodDescriptorCheckoutVehicle);
  }

  methodDescriptorReturnVehicle = new grpcWeb.MethodDescriptor(
    '/api.VehicleCheckInReturnService/ReturnVehicle',
    grpcWeb.MethodType.UNARY,
    vehicle_service_pb.VehicleReturnRequest,
    vehicle_service_pb.VehicleReturnResponse,
    (request: vehicle_service_pb.VehicleReturnRequest) => {
      return request.serializeBinary();
    },
    vehicle_service_pb.VehicleReturnResponse.deserializeBinary
  );

  returnVehicle(
    request: vehicle_service_pb.VehicleReturnRequest,
    metadata: grpcWeb.Metadata | null): Promise<vehicle_service_pb.VehicleReturnResponse>;

  returnVehicle(
    request: vehicle_service_pb.VehicleReturnRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.VehicleReturnResponse) => void): grpcWeb.ClientReadableStream<vehicle_service_pb.VehicleReturnResponse>;

  returnVehicle(
    request: vehicle_service_pb.VehicleReturnRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: vehicle_service_pb.VehicleReturnResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.VehicleCheckInReturnService/ReturnVehicle',
        request,
        metadata || {},
        this.methodDescriptorReturnVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.VehicleCheckInReturnService/ReturnVehicle',
    request,
    metadata || {},
    this.methodDescriptorReturnVehicle);
  }

}

